import React, { useEffect } from 'react';

const shared_key = 'ScWZ2oP4vZHTimskB2UFHIPCPc8ofwb0skVJA865IO8XZQvv'

const PortalCliente: React.FC = () => {
    const jwt = require('jsonwebtoken');

    const gerarJWT = () => {
        const params = new URLSearchParams(window.location.search);
        const name = params.get('name');
        const email = params.get('email');
        const external_id = params.get('external_id');

        const payload = {
            name,
            iat: Math.floor(Date.now() / 1000),
            jti: generateUniqueId(),
            email,
            external_id,
        };

        console.log(payload)

        const header = {
            alg: "HS256",
            typ: "JWT",
            kid: "app_6638d13da52036ad4c623ca8"
        }

        return jwt.sign(payload, shared_key, { header: header });
    };

    const generateUniqueId = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : ((r & 0x3) | 0x8);
            return v.toString(16);
        });
    };

    const autenticarNoZendesk = () => {
        const params = new URLSearchParams(window.location.search);
        const sistema = params.get('sistema');
        let retornourl = ''

        if (sistema === '2')
            retornourl = 'https://realtecagro.zendesk.com/hc/pt-br'
        if (sistema === '9')
            retornourl = 'https://realtecvarejo.zendesk.com/hc/pt-br'
        if (sistema === '15')
            retornourl = 'https://realtecapp.zendesk.com/hc/pt-br'

        if (retornourl !== '')
            retornourl = '?&return_to=' + retornourl

        const token = gerarJWT();

        const form = document.createElement('form');
        form.action = 'https://realtecsupport.zendesk.com/access/jwt' + retornourl;

        form.method = 'POST';
        form.value = token;

        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'jwt';
        input.value = token;

        form.appendChild(input);
        document.body.appendChild(form);
        form.submit();
    };

    useEffect(() => {
        autenticarNoZendesk();
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    return (
        <div className="container">
        </div>
    );
};

export default PortalCliente;
