import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';
import PortalCliente from './PortalCliente';
import Redirecionar from './Redirecionar';

function Principal() {

    return (
        <Router>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/PortalCliente" element={<PortalCliente />} />
                <Route path="/Redirecionar" element={<Redirecionar />} />
            </Routes>
        </Router>
    );
}

export default Principal;