import { useEffect } from 'react'


function Redirecionar() {
    useEffect(() => {
        window.location.href = `https://realtecsupport.zendesk.com/auth/v2/login/signin`;
    }, []);

    return (
        <div className="Redirecionar">
        </div>
    );
}

export default Redirecionar;